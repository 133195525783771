import {
    surgeries_getHeader,
    surgeries_formatDate,
    surgeries_formatTime,
} from "../utils";

export async function surgeries_preOpLetter(doc, model, surgery) {
    const clinics = await(model.clinics);
    const hospital = clinics.find(clinic => clinic.name === surgery.clinic.name);
    const postop_hospital = clinics.find(clinic => clinic.name === surgery.postop.clinic_name);
    const hospital_contact = hospital.name === "Calvary North Adelaide" ?
        "Please contact Pennington Eye Clinic (phone 8267 1700) after 12:30pm on the preceding business day to confirm your admission time." :
        `A nurse from ${hospital.name} will contact you for pre-operative information on the preceding business day and to confirm your admission time.`;
    const anaesthetist_contact = surgery.surgery.anaesthetist === "Dr James Dowling" ?
        "Stace Anaesthetics (phone 8236 5000)" :
        "Advanced Anaesthetics (phone 8293 2077)";
        
    doc.content.push([
        surgeries_getHeader(false),
        [
            {
                text: [
                    `\nDear ${surgery.patient.salutation} ${surgery.patient.given_names} ${surgery.patient.surname},
                
                    Your operation will be on `, { text: surgeries_formatDate(surgery.surgery.date, 3), bold: true }, ` at `, { text: surgery.clinic.name, bold: true }, `. The address is ${hospital.address}.

                    Your post-operative appointment is at `, { text: surgeries_formatTime(surgery.postop.time), bold: true }, ` on `, { text: surgeries_formatDate(surgery.postop.date, 3), bold: true }, ` with `, { text: surgery.postop.clinician_name, bold: true }, ` at ${postop_hospital.address}.
                    
                    Please complete the hospital admissions form and return to the hospital at least one week before your surgery.
                    
                    ${hospital_contact}
                    
                    You will have a local anaesthetic, and your anaesthetist will be `, { text: surgery.surgery.anaesthetist, bold: true }, `. If you would like to contact the anaesthetist before your operation, please call ${anaesthetist_contact}.\n\n`,

                    { text: `Food and drink:\n\n`, style: 'h2' },
                    `You may eat and drink up until `, { text: `6 hours`, bold: true }, ` before you are due to arrive at the day surgery.\n\n`,

                    surgery.procedure.enucleation ? "" : { text: `Medications:\n\n`, style: 'h2' },
                    surgery.procedure.enucleation ? "" : `Continue all your medications up to 6 hours prior to your operation. This also includes blood thinners, diabetic medication and glaucoma drops.\n\n`,

                    { text: `Illness:\n\n`, style: 'h2' },
                    `If there has been a change in your health after your operation date has been decided, please notify the staff at Pennington Eye Clinic.  If you have a cold or influenza the week before your operation please contact the anaesthetist.\n\n`,

                    { text: `After your operation:\n\n`, style: 'h2' },
                    `It is important that you have an adult to accompany you home and that you do not remain on your own for 24 hours after the surgery.
                    
                    You may require some pain relief - Paracetamol or similar is suitable.
                    
                    Unless advised otherwise, you will be given an appointment to see the clinical optometrist the day after your surgery. Until this appointment we advise not to change or take off your dressing. You may have a bath or shower provided you do not get the dressing wet.\n\n`,

                    { text: `Post operative drops:\n\n`, style: 'h2' },
                ]
            },
            {
                ul: [
                    `E-prescriptions for two different types of eyedrops will be sent to you on the day of your surgery and are to be started after the eye patch has been removed at your post operative appointment.`,
                    `Please use the drops four times a day for four weeks or until they run out. You can use either bottle first, however, allow five minutes between each drop.`,
                    `If you are on other eye drops you can continue to use these as directed. We advise getting a new bottle to use after your surgery to reduce the risk of infection.\n\n`
                ]
            },
            {
                text: [
                    { text: `Signs to look out for after your operation:\n\n`, style: 'h2' },

                    `You should report immediately any of the following signs:`
                ]
            },
            {
                ul: [
                    `Increasing pain`,
                    `Increasing redness`,
                    `Worsening of sight`,
                ]
            },
            {
                text: [
                    `\nPlease contact the practice on 82671700 during office hours, or
                    
                    A Prof Weng Chan (0421990757) / Dr David Sia directly (0422911322).`
                ]
            }
        ]
    ]);
}
